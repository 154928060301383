import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MainSection from "../../common/MainSection";
import ParentGrid from "../../common/ParentGrid";
import OrangeBg from "../../img/backgrounds/orange_white.jpg";
import ShopCardBoxes from "../../common/ShopCardBoxes";
import ImgCardLogo from "../../img/card_logo.jpg";

const planItems = [
	{
		title: "2 Veces x Semana BJJ",
		img: ImgCardLogo,
		link: "https://wa.me/541140358561",
		content: "Acceso a dos clases de BJJ por semana",
		price: "30900",
		paymentMethods: ["efectivo", "mp"],
	},
	{
		title: "3 Veces x Semana BJJ",
		img: ImgCardLogo,
		link: "https://wa.me/541140358561",
		content: "Acceso a tres clases de BJJ por semana (pase mensual)",
		price: "36900",
		paymentMethods: ["efectivo", "mp"],
	},
	{
		title: "Pase Full BJJ",
		img: ImgCardLogo,
		link: "https://wa.me/541140358561",
		content: "Acceso a todas las clases de BJJ (pase mensual)",
		price: "43500",
		paymentMethods: ["efectivo", "mp"],
	},
	{
		title: "Clase individual",
		img: ImgCardLogo,
		link: "https://wa.me/541140358561",
		content: "Acceso a clase individual",
		price: "16000",
		paymentMethods: ["efectivo", "mp"],
	},
	{
		title: "Clase de Prueba",
		img: ImgCardLogo,
		link: "https://wa.me/541140358561",
		content: "Acceso a clase de prueba de BJJ, se descuenta del plan elegido",
		price: "8500",
		paymentMethods: ["efectivo", "mp"],
	},
];

function Bjj() {
	return (
		<div>
			<MainSection backgroundImage={OrangeBg} pb="150px">
				<ParentGrid>
					<Stack
						width="900px"
						max-width="100%"
						spacing={5}
						justifyContent="center"
						alignItems="center"
					>
						<Typography
							variant="h3"
							component="h3"
							color="black"
							sx={{ textAlign: "center" }}
						>
							Tenemos planes adaptados a todas las necesidades:
						</Typography>
						<ShopCardBoxes plans={planItems} />
					</Stack>
				</ParentGrid>
			</MainSection>
		</div>
	);
}

export default Bjj;
